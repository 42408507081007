<style lang="scss">
.map-show {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: auto;

  .select-client {
    border: 2px solid #255598;
  }

  .floating-back-map-button {
    @media (max-width: 768px) {
      position: absolute;
      z-index: 9;
      left: 30px;
      top: 20vh;
      text-align: center;

      border-radius: 20px;
      width: 50px;
      height: 50px;
      color: white;
      background: black;

      i {
        font-size: 25px;
        color: white;
        margin-top: 12px;
      }
    }

    @media (min-width: 768px) {
      display: none;
    }
  }
  // &.content{
  //    @media (max-width: 768px) {
  //     padding: 0;
  //     width: 100%;

  //   }
  // }

  .gmap-map {
    width: 100%;
    height: calc(70vh);
    @media (max-width: 768px) {
      top: 0;
      height: calc(35vh);
    }
  }
  .container-fluid {
    @media (max-width: 768px) {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
  .sticky-t-details {
    /* Compiles to: */
    @media (max-width: 768px) {
      display: none;
    }
  }

  .client-data-map-row {
    padding: 10px 20px 0 20px;
    background: white;
    margin-left: 0px;
    border-left: 2px solid rgb(228, 228, 228);
    height: calc(70vh);

    overflow: auto; /* padding: 0 20px 0 20px; */
    @media (max-width: 768px) {
      height: inherit;
      margin: auto;
      left: 0;
      padding-top: 20px;
      box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px,
        rgb(0 0 0 / 8%) 0px 0px 0px 1px;
      background: #eef0f8;
      overflow: auto;
      position: inherit;
      position: fixed;
      /* bottom: 0; */
      /* top: 0; */
      // top:40vh;
      bottom: 0;
      height: 50vh;
      padding-bottom: 10px;
      /* top: 44
vh
; */
      right: 0;
    }

    // .row {
    //   @media (max-width: 768px) {
    //     margin-bottom: 44vh;
    //   }
    // }
    .client-data-map-col {
      padding-bottom: 10px;
      @media (max-width: 768px) {
        padding-right: 15px;
        /* background: white; */
        /* border-radius: 57
        border
px
; */
        border: none;
        padding-left: 15px;
        /* border-radius: 57px; */
      }
      .client-data-map-block {
        box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px,
          rgb(0 0 0 / 8%) 0px 0px 0px 1px;

        &:hover {
          box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px,
            rgb(0 0 0 / 8%) 0px 0px 0px 1px;
          border: 2px solid balck;
        }

        background: #f4f4f4;
        // @media (max-width: 768px) {
        //   background: #f4f4f4;
        //   /* border-radius: 57px; */
        // }
      }
    }
  }
}
</style>
<template>
  <section
    :class="
      showMap
        ? 'map-show content d-flex flex-column flex-column-fluid'
        : 'content d-flex flex-column flex-column-fluid'
    "
  >
    <div class="d-flex flex-column-fluid">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-10 col-md-12 col-12 offset-lg-1 offset-0">
            <div class="sticky-t-details">
              <div class="row">
                <div class="col-md-12">
                  <v-title
                    :title="'Appointment for ' + this.getServiceName"
                    :has-icon="true"
                    :icon="'fa fa-arrow-circle-left'"
                    :icon-size="icon_size"
                    v-on:goback="goBack"
                  ></v-title>
                  <v-title
                    :title="'Select an Office'"
                    style="font-size:14px"
                  ></v-title>
                  <!-- <div class="form-group">
                    <div class="input-icon">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Zipcode, city/state"
                        v-model="searchInput"
                      />
                      <span><i class="flaticon2-search-1 icon-md"></i></span>
                      
                    </div>
                  </div> -->

                  <div class="input-group mb-3">
                    <GmapAutocomplete
                      @place_changed="setPlace"
                      placeholder="Enter Zipcode, city/state"
                      class="form-control"
                      id="search_address"
                    />

                    <div
                      class="input-group-prepend"
                      style="cursor:pointer"
                      @click="clear()"
                    >
                      <span
                        class="input-group-text"
                        style="background: #1b1464;border: none;color: white;"
                        id="basic-addon3"
                        >Clear</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h4 class="page-info">Result For {{ yourAddress }}</h4>
                </div>
              </div>
            </div>
            <div class="row mt-1" v-if="!showMap">
              <div
                class="col-md-12 order-1 mb-5 text-center"
                v-if="dataLoading"
              >
                <loading-spinner style="margin-top:20px"> </loading-spinner>
                <h5 class="mt-3">Please wait!</h5>
              </div>
              <div
                class="col-lg-4 order-1 mb-3"
                v-for="(client, index) in clients"
                :key="index"
                v-show="clients.length > 0 && !dataLoading"
              >
                <!-- <v-service-card :service="service"></v-service-card> -->
                <v-client-card :url="url" :client="client"></v-client-card>
              </div>
              <div
                class="col-md-12 order-1 mb-5 text-center"
                v-show="clients.length < 1 && !dataLoading"
              >
                <v-no-record></v-no-record>
              </div>
            </div>
            <div class="row mt-1 " v-else>
              <div class="floating-back-map-button" @click="goBack()">
                <i class="fa fa-arrow-left"></i>
              </div>

              <div class="col-sm-12 col-md-7 col-lg-8 pr-0">
                <gmap-map
                  ref="mapRef"
                  class="gmap-map"
                  :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: true,
                    streetViewControl: false,
                    rotateControl: true,
                    fullscreenControl: true,
                    disableDefaultUi: false
                  }"
                  @dragend="ondragend"
                  v-on:zoom_changed="ondragend"
                  @click="onClick"
                  :center="center"
                  :zoom="zoomLevel"
                >
                  <!-- <GmapCircle
                    :center="center"
                    :radius="radius"
                    :visible="true"
                    :options="{ fillColor: 'blue', fillOpacity: 0.2 }"
                  ></GmapCircle> -->

                  <gmap-marker
                    :icon="{
                      url: require('../../../assets/images/map/svg/black-marker.svg')
                    }"
                    :key="clients.length + 1"
                    :position="{
                      lat: center.lat,
                      lng: center.lng
                    }"
                  ></gmap-marker>
                  <gmap-marker
                    :icon="{
                      url: require('../../../assets/images/map/svg/purple-marker.svg')
                    }"
                    @click="selectClient(item.id)"
                    v-for="(item, index) in clients"
                    :key="index"
                    :position="{
                      lat: parseFloat(item.lat),
                      lng: parseFloat(item.long)
                    }"
                  ></gmap-marker>
                </gmap-map>
              </div>
              <div class="col-sm-12 col-md-5 col-lg-4 pl-0">
                <div class="client-data-map-row">
                  <div class="row">
                    <div
                      :show="clients.length > 0 && !dataLoading"
                      class="col-md-12 client-data-map-col mb-2"
                      v-for="(client, index) in clients"
                      :key="index"
                      :id="client.id + '_ref_scroll'"
                    >
                      <v-client-card
                        :select="selectedId"
                        :url="url"
                        class="client-data-map-block"
                        :client="client"
                      ></v-client-card>
                    </div>

                    <div
                      class="col-md-12 order-1 mb-5 text-center"
                      v-show="clients.length < 1 && !dataLoading"
                    >
                      <v-no-record
                        :msg="
                          'No locations found within this map.<br> Search for a City/Zip-code or select another region on the map.'
                        "
                      ></v-no-record>
                    </div>

                    <div
                      class="col-md-12 order-1 mb-5 text-center"
                      v-if="dataLoading"
                    >
                      <loading-spinner style="margin-top:20px">
                      </loading-spinner>
                      <h5 class="mt-3">Please wait!</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-copy-right-footer></v-copy-right-footer>
    </div>
  </section>
</template>
<script>
import vTitle from "@/components/frontend/title";
import vClientCard from "@/components/frontend/clientcard";
import vNoRecord from "@/components/frontend/norecord";
import vCopyRightFooter from "@/components/frontend/copyrightfooter";
import { mapGetters } from "vuex";
import { FETCH_F_CLIENTS } from "@/core/services/store/actions.type";

import loadingSpinner from "@/components/frontend/spinner.vue";

// import Title from '../../components/frontend/title.vue';
export default {
  components: {
    vTitle,
    vClientCard,
    vNoRecord,
    loadingSpinner,
    vCopyRightFooter
  },

  data() {
    return {
      selectedId: 0,
      searchInput: "",
      center: {
        lat: 0,
        lng: 0
      },
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
      yourLat: 0,
      youtLong: 0,
      yourAddress: "",
      errMessage: "",
      clients: [],
      icon_size: 22,
      dataLoading: true,
      url: "",
      showMap: false,
      zoomLevel: 0,
      radius: 100,
      c: []
      // zipCode_ci:0,
    };
  },
  mounted() {
    if (this.getServiceName == "") {
      this.$router.push({
        name: "frontend.home",
        params: { slug: this.$route.params.client_slug }
      });
    }
    this.locateGeoLocation();
  },
  computed: {
    ...mapGetters(["getClientSlug", "getServiceName", "getServiceProviderData"])
  },
  methods: {
    onClick(e) {
      var jsonLatLng = JSON.parse(JSON.stringify(e)).latLng;

      this.center = jsonLatLng;

      this.displayLocation(true);
    },
    ondragend() {
      this.$refs.mapRef.$mapPromise.then(map => {
        var jsonLatLng = JSON.parse(JSON.stringify(map.getCenter()));
        this.center = jsonLatLng;
        this.displayLocation(true);
      });
    },
    funZoomLevel() {
      this.radius = parseInt(this.radius);
      var scale = this.radius / 620;
      this.zoomLevel = parseInt(16 - Math.log(scale) / Math.log(2));
      this.$refs.mapRef.$mapPromise.then(map => {
        map.panTo(this.center);
      });
    },
    selectClient(id) {
      var _id = id + "_ref_scroll";
      document.getElementById(_id).scrollIntoView({
        behavior: "smooth"
      });
      this.selectedId = id;
    },
    setPlace(place) {
      this.clients = [];

      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      var address = place.formatted_address;
      this.yourAddress = address;
      this.fetchFClients(true);
    },
    clear() {
      document.getElementById("search_address").value = "";
    },
    goBack() {
      if (this.showMap) {
        document.getElementById("search_address").value = "";
        this.clients = [];
        this.showMap = false;
        this.locateGeoLocation();
      } else {
        this.$router.push({
          name: "frontend.home",
          params: { slug: this.$route.params.client_slug }
        });
      }
    },

    initMarker(loc) {
      this.existingPlace = loc;
    },
    displayLocation(flag = false) {
      var request = new XMLHttpRequest();

      var method = "GET";
      var url =
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        this.center.lat +
        "," +
        this.center.lng +
        "&key=" +
        this.getServiceProviderData.key;
      var async = true;

      request.open(method, url, async);
      var that = this;
      // var j = 0;
      // var address = "";

      request.onreadystatechange = function() {
        if (request.readyState == 4 && request.status == 200) {
          var data = JSON.parse(request.responseText);
          if (data.results[0]) {
            var address = data.results[0].formatted_address;
            that.yourAddress = address;
            that.fetchFClients(flag);
          }
        }
      };
      //  that.fetchFClients();

      request.send();
    },

    locateGeoLocation: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        this.displayLocation();
      });
    },
    fetchFClients(first = false) {
      this.selectedId = 0;
      this.dataLoading = true;
      this.$store
        .dispatch(FETCH_F_CLIENTS, {
          center: this.center,
          slug: this.$route.params.slug,
          client_slug: this.$route.params.client_slug
        })
        .then(data => {
          this.clients = data.data;
          this.radius = data.map_radius * 1.60934 * 1000;
          if (data.data.length > 0) {
            if (first) {
              this.showMap = first;
            }
            this.funZoomLevel();
          }
          this.url = data.url;
          this.dataLoading = false;
        })
        .catch(() => {
          this.dataLoading = false;
        });
    }
  }
};
</script>
