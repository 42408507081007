<style lang="scss">
#service-container-app {
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  }
}
</style>
<template lang="">
  <div
    :class="select == client.id ? 'select-client' : ''"
    class="bg-light-default rounded p-5"
    id="service-container-app"
    :ref="client.id"
    @click="
      openAppModalType(
        client.id,
        client.display_name == null || client.display_name == ''
          ? client.name + ', ' + client.full_address
          : client.display_name + ', ' + client.full_address,
        client.enable_on_site_payment,
        client.client_timezone
      )
    "
  >

    <div class="d-flex align-items-center">
      <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
        <div
          class="symbol-label"
          :style="
            'background-size: contain;background-image: url(' +
              url +
              client.image +
              ')'
          "
        ></div>
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <a
          class="text-dark-cust font-weight-bolder font-size-lg text-hover-primary-cust mb-1"
          >{{ client.display_name }}</a
        ><span class="text-dark-50 font-weight-normal font-size-sm">
          {{ client.full_address }}
        </span>
      </div>
    </div>
    <p class="mt-4">{{ client.description }}</p>
    <b-modal
      id="otp"
      no-close-on-backdrop
      centered
      size="lg"
      v-model="openAppType"
      :title="'Choose a fingerprint capture method'"
    >
      <div class="row text-center mt-3">
        <div class="col-6 p-3 hover-shadow">
          <div
            class="card"
            @click="SelectAppType('live scan')"
            @mouseover="mouseOverScan('live scan')"
            @mouseleave="mouseLeaveScan('live scan')"
            :style="
              liveScanActive
                ? 'border: 2px solid green; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);'
                : hoverLiveScan
                ? 'cursor:pointer; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                : ''
            "
          >
            <div
              class="card-header"
              style="color: rgb(46 35 165);font-weight:900; font-size=1.5rem;"
            >
              <h3>Live Scan</h3>
            </div>
            <div class="card-body">
              <img
                src="@/assets/images/scantype/livescan.jpg"
                height="200px"
                width="200px"
              />
              <h5 class="mt-5">Get fingerprint captured in the location</h5>
            </div>
          </div>
        </div>

        <div class="col-6 p-3 hover-shadow">
          <div
            class="card"
            @click="SelectAppType('card scan')"
            @mouseover="mouseOverScan('card scan')"
            @mouseleave="mouseLeaveScan('card scan')"
            :style="
              cardScanActive
                ? 'border: 2px solid green; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);'
                : hoverCardScan
                ? 'cursor:pointer; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                : ''
            "
          >
            <div
              class="card-header"
              style="color: rgb(46 35 165); font-weight:900; font-size=1.5rem;"
            >
              <h3>Card Scan</h3>
            </div>
            <div class="card-body">
              <img
                src="@/assets/images/scantype/cardscan.jpg"
                height="200px"
                width="200px"
              />
              <h5 class="mt-5">Send fingerprint card in mail</h5>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            @click="confirmAppType()"
            variant="success"
            size="sm"
            class="float-right ml-5"
          >
            Confirm
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  SET_F_CLIENT_NAME,
  SET_ADD_RATE_QTY,
  SET_RATE_TOTAL_AMOUNT,
  SET_F_CLIENT_ENABLE_ON_SITE_PAYMENT,
  SET_TIME_ZONE,
  SET_APP_TYPE,
} from "@/core/services/store/mutations.type";

import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "getServiceType",
      "getIsDrugTesting",
      "getShowCaptureOption",
    ]),
  },
  data() {
    return {
      id: "",
      name: "",
      enabled_on_site_payment: false,
      timezone: "",
      liveScanActive: false,
      cardScanActive: false,
      app_type: "",
      openAppType: false,
      hoverCardScan: false,
      hoverLiveScan: false,
    };
  },
  props: {
    select: {
      type: Number,
      default: 0,
    },
    url: {
      type: String,
      default: "",
    },

    client: {
      type: Object,
      default: function () {
        return {
          id: 1,
          name: "Service Title",
          image: "/media/stock-600x400/img-20.jpg",
          description: "Description Here",
        };
      },
    },
  },

  methods: {
    mouseOverScan(value) {
      if (value == "card scan") {
        this.hoverCardScan = true;
        this.hoverLiveScan = false;
      } else {
        this.hoverCardScan = false;
        this.hoverLiveScan = true;
      }
    },

    openAppModalType(id, name, enable_on_site_payment, timezone) {
      if (!this.getShowCaptureOption) {
        this.openBookNow(id, name, enable_on_site_payment, timezone, "");
      } else {
        this.openAppType = true;
        this.id = id;
        this.name = name;
        this.enabled_on_site_payment = enable_on_site_payment;
        this.timezone = timezone;
      }
    },
    mouseLeaveScan(value) {
      if (value == "card scan") {
        this.hoverCardScan = false;
      } else {
        this.hoverLiveScan = false;
      }
    },

    confirmAppType() {
      if (this.app_type == "") {
        this.$toastr.e("Please choose an option");
      } else {
        this.openBookNow(
          this.id,
          this.name,
          this.enabled_on_site_payment,
          this.timezone,
          this.app_type
        );
        // this.$store.commit(SET_APP_TYPE, this.app_type);
      }
    },
    SelectAppType(apptype) {
      if (apptype == "live scan") {
        this.cardScanActive = false;
        this.liveScanActive = true;
        this.app_type = "live scan";
      } else if (apptype == "card scan") {
        this.liveScanActive = false;
        this.cardScanActive = true;
        this.app_type = "card scan";
      }
    },
    openBookNow(id, name, enable_on_site_payment, timezone, app_type) {
      var client_slug = this.$route.params.client_slug;

      this.$store.commit(SET_ADD_RATE_QTY, 0);
      this.$store.commit(SET_RATE_TOTAL_AMOUNT, 0);

      if (this.getServiceType == "citizen") {
        //  this.$router.push({ name: "client.appointment.citizenship"});
        this.$router.push({
          name: "client.appointment.citizenship",
          params: { id: id, client_slug: client_slug },
        });
      } else if (this.getServiceType == "DISCLOSURE") {
        this.$router.push({
          name: "client.appointment.disclosure",
          params: { id: id, client_slug: client_slug },
        });
      } else if (this.getServiceType == "none") {
        this.$router.push({
          name: "frontend.booknow",
          params: { id: id, client_slug: client_slug },
        });
      }
      this.$store.commit(SET_F_CLIENT_NAME, name);
      this.$store.commit(SET_TIME_ZONE, timezone);
      this.$store.commit(SET_APP_TYPE, app_type);
      this.$store.commit(
        SET_F_CLIENT_ENABLE_ON_SITE_PAYMENT,
        enable_on_site_payment
      );
      // this.$store.commit(UPDATE_PAYMENT_TYPE, enable_on_site_payment ? 1 : 0);
    },
  },
};
</script>
