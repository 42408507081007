<template>
  <h3 class="mb-3">
    <i
      v-if="hasIcon"
      class="text-dark"
      :class="icon"
      :style="'font-size:' + iconSize + 'px;margin-right:10px;cursor:pointer'"
      @click="goBack()"
    ></i
    >{{ title }}
  </h3>
</template>
<script>
export default {
  methods: {
    goBack() {
      this.$emit("goback");
    }
  },
  props: {
    title: {
      default: "title",
      type: String
    },
    isMobile: {
      default: false,
      type: Boolean
    },
    hasIcon: {
      default: false,
      type: Boolean
    },
    icon: {
      default: "",
      type: String
    },
    iconSize: {
      default: 18,
      type: Number
    }
  }
};
</script>
