var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:_vm.client.id,staticClass:"bg-light-default rounded p-5",class:_vm.select == _vm.client.id ? 'select-client' : '',attrs:{"id":"service-container-app"},on:{"click":function($event){return _vm.openAppModalType(
      _vm.client.id,
      _vm.client.display_name == null || _vm.client.display_name == ''
        ? _vm.client.name + ', ' + _vm.client.full_address
        : _vm.client.display_name + ', ' + _vm.client.full_address,
      _vm.client.enable_on_site_payment,
      _vm.client.client_timezone
    )}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4"},[_c('div',{staticClass:"symbol-label",style:('background-size: contain;background-image: url(' +
            _vm.url +
            _vm.client.image +
            ')')})]),_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('a',{staticClass:"text-dark-cust font-weight-bolder font-size-lg text-hover-primary-cust mb-1"},[_vm._v(_vm._s(_vm.client.display_name))]),_c('span',{staticClass:"text-dark-50 font-weight-normal font-size-sm"},[_vm._v(" "+_vm._s(_vm.client.full_address)+" ")])])]),_c('p',{staticClass:"mt-4"},[_vm._v(_vm._s(_vm.client.description))]),_c('b-modal',{attrs:{"id":"otp","no-close-on-backdrop":"","centered":"","size":"lg","title":'Choose a fingerprint capture method'},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right ml-5",attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.confirmAppType()}}},[_vm._v(" Confirm ")])],1)]},proxy:true}]),model:{value:(_vm.openAppType),callback:function ($$v) {_vm.openAppType=$$v},expression:"openAppType"}},[_c('div',{staticClass:"row text-center mt-3"},[_c('div',{staticClass:"col-6 p-3 hover-shadow"},[_c('div',{staticClass:"card",style:(_vm.liveScanActive
              ? 'border: 2px solid green; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);'
              : _vm.hoverLiveScan
              ? 'cursor:pointer; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
              : ''),on:{"click":function($event){return _vm.SelectAppType('live scan')},"mouseover":function($event){return _vm.mouseOverScan('live scan')},"mouseleave":function($event){return _vm.mouseLeaveScan('live scan')}}},[_c('div',{staticClass:"card-header",staticStyle:{"color":"rgb(46 35 165)","font-weight":"900"}},[_c('h3',[_vm._v("Live Scan")])]),_c('div',{staticClass:"card-body"},[_c('img',{attrs:{"src":require("@/assets/images/scantype/livescan.jpg"),"height":"200px","width":"200px"}}),_c('h5',{staticClass:"mt-5"},[_vm._v("Get fingerprint captured in the location")])])])]),_c('div',{staticClass:"col-6 p-3 hover-shadow"},[_c('div',{staticClass:"card",style:(_vm.cardScanActive
              ? 'border: 2px solid green; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);'
              : _vm.hoverCardScan
              ? 'cursor:pointer; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
              : ''),on:{"click":function($event){return _vm.SelectAppType('card scan')},"mouseover":function($event){return _vm.mouseOverScan('card scan')},"mouseleave":function($event){return _vm.mouseLeaveScan('card scan')}}},[_c('div',{staticClass:"card-header",staticStyle:{"color":"rgb(46 35 165)","font-weight":"900"}},[_c('h3',[_vm._v("Card Scan")])]),_c('div',{staticClass:"card-body"},[_c('img',{attrs:{"src":require("@/assets/images/scantype/cardscan.jpg"),"height":"200px","width":"200px"}}),_c('h5',{staticClass:"mt-5"},[_vm._v("Send fingerprint card in mail")])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }